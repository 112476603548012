import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import { AngularFireAuth } from '@angular/fire/auth';

// Local Storage
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class GetvinService {

  //Local Storage
  LocalVIN: string;

  constructor(
    private afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    //Local Storage
    private storage: Storage,
  ) { }

  getVehicleData() {
    return this.storage.get('LocalVIN').then((val) => {
      return val;
    }).then((value) => {
      return this.afs.collection('vehicles').doc(value).collection('vehicle').snapshotChanges();
    });
  }
  // Performance
  getPerformanceData() {
    return this.storage.get('LocalVIN').then((val) => {
      return val;
    }).then((value) => {
      return this.afs.collection('vehicles').doc(value).collection('Performance').snapshotChanges();
    });
  }
  // Dashboard
  getDashboardData() {
    return this.storage.get('LocalVIN').then((val) => {
      return val;
    }).then((value) => {
      return this.afs.collection('vehicles').doc(value).collection('Dashboard').snapshotChanges();
    });
  }

  // GasCharge
  getGaschargeData() {
    return this.storage.get('LocalVIN').then((val) => {
      return val;
    }).then((value) => {
      return this.afs.collection('vehicles').doc(value).collection('gascharge').snapshotChanges();
    });
  }

}