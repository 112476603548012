import { Injectable, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import { AngularFireAuth } from '@angular/fire/auth';

// Local Storage
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService implements OnInit{

  //Local Storage
  LocalUID: string;
  userId: string;
  LocalAuth: string;
  LDlastLog: string;

  constructor(
    private afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    //Local Storage
    private storage: Storage,
  ) { 
    this.userId = firebase.auth().currentUser.uid
  }

  ngOnInit() {
    this.userId = firebase.auth().currentUser.uid
    this.storage.get('LocalUID').then((val) => {
      this.LocalUID = val;
    });
  }

  createTask(value) {
    return new Promise<any>((resolve, reject) => {
      this.afs.collection('vehicles').doc(value.vin).collection('vehicle').doc('carData').set({
        uid: value.uid,
        vin: value.vin,
        brand: value.brand,
        model: value.model,
        version: value.version,
        year: value.year,
        plates: value.plates,
        country: value.country,
        state: value.state,
        initialMileage: value.initialMileage,
        registrationLog: new Date().getTime(),
      }),
        this.afs.collection('users').doc(value.uid).collection('cars').doc(value.uid).update({
          auth: 1,
          uid: value.uid,
          vincar: value.vin
        }),
        // Dashboard initial
        this.afs.collection('vehicles').doc(value.vin).collection('Dashboard').doc('dashData').set({
          lastLog: 1586089560000,
          totalCharges: 0,
          totalGas: 0,
          totalKmTraveled: 0,
          totalMoney: 0
        }),
        // Performance initial
        this.afs.collection('vehicles').doc(value.vin).collection('Performance').doc('PerData').set({
          BestDate: 1586107560000,
          BestLat: 20.6122835,
          BestLong: -100.4802573,
          BestPerformance: 0,
          BestPrice: 0,
          CheapDate: 1586107560000,
          CheapLat: 20.6122835,
          CheapLong: -100.4802573,
          CheapPerformance: 0,
          CheapPrice: 0,
          LowerDate: 1586107560000,
          LowerLat: 20.6122835,
          LowerLong: -100.4802573,
          LowerPerformance: 0,
          LowerPrice: 0,
        })
          .then(
            res => resolve(res),
            err => reject(err)
          )
        this.storage.set('LocalAuth', 1)
        this.storage.set('LocalVIN', value.vin)
        this.storage.set('LDlastLog', 1586089560000)
        
    })
  }

}