import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import { AngularFireAuth } from '@angular/fire/auth';

// Local Storage
import { Storage } from '@ionic/storage';


@Injectable({
  providedIn: 'root'
})
export class UsersService {

  //Local Storage
  LocalUID: string;

  constructor(
    private afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    //Local Storage
    private storage: Storage,
  ) { }

  getUserData() {
    return this.storage.get('LocalUID').then((val) => {
      return val;
    }).then((value) => {
      return this.afs.collection('users').doc(value).collection('cars').snapshotChanges();
    });
  }


}
